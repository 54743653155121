<template>
  <div class="main">
    <div class="container">
      <div class="detail_header">
        <div class="title">ECI Updates</div>
        <div class="year_box">
          Year:
          <el-select
            v-model="year"
            placeholder="Year"
            @change="getYear"
            clearable
          >
            <el-option value="2022">2022</el-option>
            <el-option value="2019">2019</el-option>
            <el-option value="2018">2018</el-option>
            <el-option value="2017">2017</el-option>
          </el-select>
        </div>
      </div>
      <div class="news_box">
        <div class="news_item" v-for="(item, index) in list" :key="index">
          <div class="img">
            <img v-if="item.titleimg != ''" :src="$OSSURL + item.titleimg" />
          </div>
          <div class="con">
            <div class="title">{{ item.title }}</div>
            <div class="text" v-html="item.desc"></div>
            <div class="readmore" @click="toDetail(item.id)">READ MORE</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DATA } from '@/data/f_updates.js';
export default {
  name: 'Index',
  components: {},
  data() {
    return {
      year: '',
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.list = [];
      if (this.year != '') {
        DATA.forEach(item => {
          if (item.year == this.year) {
            this.list.push(item);
          }
        });
      } else {
        this.list = DATA;
      }
    },
    toDetail(id) {
      this.$router.push({
        name: 'FestivalUpdatesDetail',
        query: { id },
      });
    },
    getYear() {
      console.log(this.year);
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/news.scss';
</style>
